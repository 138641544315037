var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"venue"},[_c('TopNavBar'),_c('Carousel',{attrs:{"carousels":_vm.carousels}}),_c('div',{staticClass:"design_box"},[_c('Title',{attrs:{"title":"设计理念"}}),_c('div',{staticClass:"design"},[_c('div',{staticClass:"designs"},[_vm._l((_vm.designs),function(item,index){return [(index < 3)?_c('div',{key:index,staticClass:"item"},[_c('h3',[_c('img',{attrs:{"src":require('@/assets/images/product/venue/icon' +
                    index +
                    '.png'),"alt":""}}),_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',[_vm._v(" "+_vm._s(item.content)+" ")])]):_vm._e()]})],2),_c('img',{attrs:{"src":require("@/assets/images/product/venue/bg1.png"),"alt":""}}),_c('div',{staticClass:"designs"},[_vm._l((_vm.designs),function(item,index){return [(index >= 3)?_c('div',{key:index,staticClass:"item"},[_c('h3',[_c('img',{attrs:{"src":require('@/assets/images/product/venue/icon' +
                    index +
                    '.png'),"alt":""}}),_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',[_vm._v(" "+_vm._s(item.content)+" ")])]):_vm._e()]})],2)])],1),_c('div',{staticClass:"characteristic_box"},[_c('Title',{attrs:{"title":"系统特点"}}),_c('img',{attrs:{"src":require("@/assets/images/product/venue/bg2.png"),"alt":""}})],1),_c('div',{staticClass:"box"},[_c('div',{staticClass:"framework_box"},[_c('Title',{attrs:{"title":"架构图"}}),_vm._m(0)],1)]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"application_box"},[_c('Title',{attrs:{"title":"场景及应用"}}),_vm._m(1)],1)]),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"framework"},[_c('img',{attrs:{"src":require("@/assets/images/product/venue/bg3.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"application"},[_c('div',{staticClass:"item left",staticStyle:{"left":"-62px"}},[_vm._v("藏品管理")]),_c('div',{staticClass:"item left",staticStyle:{"left":"-122px"}},[_vm._v("展品管理")]),_c('div',{staticClass:"item left",staticStyle:{"left":"-68px"}},[_vm._v("资源管理")]),_c('div',{staticClass:"item left",staticStyle:{"left":"210px"}},[_vm._v("权限管理")]),_c('div',{staticClass:"item left",staticStyle:{"left":"124px"}},[_vm._v("信息发布")]),_c('div',{staticClass:"item left",staticStyle:{"left":"210px"}},[_vm._v("票务管理")]),_c('div',{staticClass:"item right",staticStyle:{"right":"-62px"}},[_vm._v("客流监测")]),_c('div',{staticClass:"item right",staticStyle:{"right":"-122px"}},[_vm._v("中控管理")]),_c('div',{staticClass:"item right",staticStyle:{"right":"-70px"}},[_vm._v("监测系统")]),_c('div',{staticClass:"item right",staticStyle:{"right":"218px"}},[_vm._v("志愿者管理")]),_c('div',{staticClass:"item right",staticStyle:{"right":"120px"}},[_vm._v("研学管理")]),_c('div',{staticClass:"item right",staticStyle:{"right":"218px"}},[_vm._v("文创销售")]),_c('div',{staticClass:"middle"},[_vm._v("智慧政务")])])
}]

export { render, staticRenderFns }