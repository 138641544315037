<template>
  <div class="venue">
    <TopNavBar />
    <Carousel :carousels="carousels" />
    <div class="design_box">
      <Title title="设计理念" />
      <div class="design">
        <div class="designs">
          <template v-for="(item, index) in designs">
            <div :key="index" v-if="index < 3" class="item">
              <h3>
                <img
                  :src="
                    require('@/assets/images/product/venue/icon' +
                      index +
                      '.png')
                  "
                  alt=""
                />
                {{ item.title }}
              </h3>
              <p>
                {{ item.content }}
              </p>
            </div>
          </template>
        </div>
        <img src="@/assets/images/product/venue/bg1.png" alt="" />
        <div class="designs">
          <template v-for="(item, index) in designs">
            <div :key="index" v-if="index >= 3" class="item">
              <h3>
                <img
                  :src="
                    require('@/assets/images/product/venue/icon' +
                      index +
                      '.png')
                  "
                  alt=""
                />
                {{ item.title }}
              </h3>
              <p>
                {{ item.content }}
              </p>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="characteristic_box">
      <Title title="系统特点" />
      <img src="@/assets/images/product/venue/bg2.png" alt="" />
    </div>
    <div class="box">
      <div class="framework_box">
        <Title title="架构图" />
        <div class="framework">
          <img src="@/assets/images/product/venue/bg3.png" alt="" />
        </div>
      </div>
    </div>
    <div class="box">
      <div class="application_box">
        <Title title="场景及应用" />
        <div class="application">
          <div class="item left" style="left: -62px">藏品管理</div>
          <div class="item left" style="left: -122px">展品管理</div>
          <div class="item left" style="left: -68px">资源管理</div>
          <div class="item left" style="left: 210px">权限管理</div>
          <div class="item left" style="left: 124px">信息发布</div>
          <div class="item left" style="left: 210px">票务管理</div>
          <div class="item right" style="right: -62px">客流监测</div>
          <div class="item right" style="right: -122px">中控管理</div>
          <div class="item right" style="right: -70px">监测系统</div>
          <div class="item right" style="right: 218px">志愿者管理</div>
          <div class="item right" style="right: 120px">研学管理</div>
          <div class="item right" style="right: 218px">文创销售</div>
          <div class="middle">智慧政务</div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
export default {
  name: "Venue",
  data() {
    return {
      designs: [
        {
          title: "决策可视化",
          content:
            "利用大数据可视化技术、GIS技术、3D建模技术、数字孪生技术、实景地图及地理标志实现决策应用和监测应用的深度还原和融合。",
        },
        {
          title: "应用公众化",
          content: "面向公众提供全矩阵化的公众数字化场馆参观应用。",
        },
        {
          title: "操作智能化",
          content:
            "采用AI智能语音交互控制技术，实现智能监测大屏以及各类常用系统和应用的智能语音交互控制模式。",
        },
        {
          title: "功能扩展化",
          content:
            "基于场馆智慧运维的场景及功能多元化扩展，适时对智能监测大屏可视化应用进行功能扩展和迭代，保证场馆整体智慧运维的同步提升。",
        },
        {
          title: "架构层次化",
          content:
            "保证物理层、软件层、数据层的优化设计和应用，同时在应用层实现五层应用架构。",
        },
        {
          title: "监测实时化",
          content:
            "视频流、传感器等各类监测设备数据以及场馆各类业务流程和节点数据实时展示和呈现。",
        },
      ],
      carousels: [
        {
          imgUrl: require("@/assets/images/carousels/9.png"),
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.venue {
  .box {
    display: flex;
    justify-content: center;
  }
  .design_box {
    margin: 85px 0 70px;
    .design {
      margin-top: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 60px;
      > img {
        width: 950px;
        height: 550px;
      }
      .designs {
        height: 520px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .item {
          h3 {
            font-family: "AlibabaPuHuiTi-Regular";
            font-weight: 400;
            font-size: 20px;
            height: 40px;
            color: #333333;
            display: flex;
            align-items: center;
            img {
              width: 25px;
              height: 25px;
              margin-right: 10px;
            }
          }
          p {
            font-family: "AlibabaPuHuiTi-Light";
            font-weight: 200;
            font-size: 16px;
            line-height: 40px;
            text-align: left;
          }
        }
      }
      .designs:first-child {
        .item:nth-child(1):hover {
          h3 {
            color: #0ab394;
          }
          p {
            color: #0ab394;
          }
        }
        .item:nth-child(2):hover {
          h3 {
            color: #fe9627;
          }
          p {
            color: #fe9627;
          }
        }
        .item:nth-child(3):hover {
          h3 {
            color: #006eff;
          }
          p {
            color: #006eff;
          }
        }
      }
      .designs:last-child {
        .item:nth-child(1):hover {
          h3 {
            color: #fe9627;
          }
          p {
            color: #fe9627;
          }
        }
        .item:nth-child(2):hover {
          h3 {
            color: #006eff;
          }
          p {
            color: #006eff;
          }
        }
        .item:nth-child(3):hover {
          h3 {
            color: #0ab394;
          }
          p {
            color: #0ab394;
          }
        }
      }
    }
  }
  .characteristic_box {
    padding: 55px 0 80px;
    height: 788px;
    background-color: #f6fafd;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    img {
      width: 1520px;
      height: 653px;
    }
  }
  .framework_box {
    margin-top: 105px;
    .framework {
      margin-top: 70px;
      width: 1520px;
      height: 1048px;
      background-color: #ffffff;
      box-shadow: 0px 0px 30px #eeeeeeb3;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 1420px;
        height: 948px;
      }
    }
  }
  .application_box {
    width: 100vw;
    padding: 65px 0 80px;
    height: 652px;
    background: linear-gradient(180deg, #ffffff 0%, #ddeaff 101%);
    .application {
      position: relative;
      margin: 50px auto 0;
      height: 548px;
      width: 885px;
      background-image: url(~@/assets/images/product/venue/bg4.png);
      background-repeat: no-repeat;
      background-size: cover;
      .item {
        position: absolute;
        width: 110px;
        font-family: "AlibabaPuHuiTi-Regular";
        font-weight: 400;
        font-size: 20px;
        line-height: 40px;
        color: #333;
      }
      .item:nth-child(-n + 6) {
        text-align: right;
      }
      .item:nth-child(n + 7) {
        text-align: left;
      }
      .item:nth-child(1),
      .item:nth-child(7) {
        top: 22px;
      }
      .item:nth-child(2),
      .item:nth-child(5),
      .item:nth-child(8),
      .item:nth-child(11) {
        top: 252px;
      }
      .item:nth-child(3),
      .item:nth-child(9) {
        bottom: 26px;
      }
      .item:nth-child(4),
      .item:nth-child(10) {
        top: 92px;
      }
      .item:nth-child(6),
      .item:nth-child(12) {
        bottom: 95px;
      }
      .middle {
        position: absolute;
        width: 70px;
        left: 50%;
        top: 50%;
        margin-left: 5px;
        transform: translate(-50%, -50%);
        font-weight: 400;
        font-size: 25px;
        letter-spacing: 0.5px;
        line-height: 40px;
      }
    }
  }
}
</style>
